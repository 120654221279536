<template>
    <main class="main mt-8">
        <div class="container">
            <div class="row" dir="rtl">
                <aside class="sidebar col-lg-2">
                    <div class="widget widget-dashboard">
                        <h3 class="widget-title text-right">الحساب</h3>
                        <ul class="list">
                            <li><a href="/account">معلومات الحساب</a></li>
                            <li class="active"><a @click.prevent>الطلبيات</a></li>
                            <li><a href="/wallet">المحفظة</a></li>
                            <li><a href="/addresses">العناوين</a></li>
                        </ul>
                    </div>
                </aside> 
                <div class="col-lg-6  dashboard-content">
                    <div class="tab-content tabs">
                        <input type="radio" class="tabs__radio" value="pending" name="orderStatus" @change="changeTab()" id="pending" checked>
                        <label for="pending" class="tabs__label">الطلبيات</label>
                        
                        <div class="tabs__content">
                            <b-skeleton-table v-if="!loading"
                            :rows="8"
                            :columns="5"
                            :table-props="{ bordered: true, striped: true }"
                            ></b-skeleton-table>
                            <table class="table text-right" v-else>
                                <thead>
                                <tr class="table-active">
                                    <th scope="col">رقم الطلب</th>
                                    <th scope="col">حالة الطلب</th>
                                    <th scope="col">العنوان</th>
                                    <th scope="col">الاجمالي</th>
                                    <th scope="col">التاريخ</th>
                                    <th scope="col">تفاصيل الطلب</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item, i) in pending.data" :key="i">
                                    <th scope="row">{{ item.id }}</th>
                                    <td>
                                    <div v-if="item.orderStatus == 'PENDING'" class="text-warning">
                                        <i class="fa fa-clock"></i>
                                        بإنتضار الموافقة
                                    </div>
                                    <div v-if="item.orderStatus == 'PAYED_AND_PENDING'" class="text-warning">
                                        <i class="fa fa-clock"></i>
                                        تم الدفع وبإنتضار الموافقة
                                    </div>
                                    <div v-if="item.orderStatus == 'PREPARING'" class="text-muted">
                                        <i class="fa fa-clock"></i>
                                        قيد التجهيز
                                    </div>
                                    <div v-if="item.orderStatus == 'SHIPPED'" class="text-info">
                                        <i class="fa fa-shipping-fast"></i>
                                        تحت الشحن
                                    </div>
                                    <div v-if="item.orderStatus == 'WAITING_FOR_DRIVER'" class="text-info">
                                        <i class="fa fa-motorcycle"></i>
                                        في انتضار السائق
                                    </div>
                                    </td>
                                    <td>
                                        {{ item.addressAddress + ' - ' + item.zoneName }}
                                    </td>
                                    <td>
                                        الاجمالي : {{ item.total }} د.ل
                                    </td>
                                    <td>
                                        {{ item.createdDate }}
                                    </td>
                                    <td style="text-align-last: center;" class="text-success">
                                        <a :href="'/order/'+item.id" style="font-size: 20px;"><i class="fa fa-database"></i> </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <input type="radio" class="tabs__radio" value="delivered" name="orderStatus" @change="changeTab()" id="delivered">
                        <label for="delivered" class="tabs__label">الطلبيات تم ايصالها</label>
                        <div class="product-desc-content tabs__content">
                            <table class="table text-right">
                                <thead>
                                <tr class="table-active">
                                    <th scope="col">رقم الطلب</th>
                                    <th scope="col">حالة الطلب</th>
                                    <th scope="col">العنوان</th>
                                    <th scope="col">الاجمالي</th>
                                    <th scope="col">التاريخ</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item, i) in delivered.data" :key="i">
                                    <th scope="row">{{ item.id }}</th>
                                    <td>
                                        <i class="fa fa-check"></i>
                                    </td>
                                    <td>
                                        {{ item.addressAddress + ' - ' + item.zoneName }}
                                    </td>
                                    <td>
                                        الاجمالي : {{ item.total }} د.ل
                                    </td>
                                    <td>
                                        {{ item.createdDate }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div><!-- End .product-desc-content -->
                        <input type="radio" class="tabs__radio" value="cancelled" name="orderStatus" @change="changeTab()" id="cancelled">
                        <label for="cancelled" class="tabs__label">الطلبيات الملغية</label>
                        <div class="product-desc-content tabs__content">
                            <table class="table text-right">
                                <thead>
                                <tr class="table-active">
                                    <th scope="col">رقم الطلب</th>
                                    <th scope="col">حالة الطلب</th>
                                    <th scope="col">العنوان</th>
                                    <th scope="col">الاجمالي</th>
                                    <th scope="col">التاريخ</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item, i) in cancelled.data" :key="i">
                                    <th scope="row">{{ item.id }}</th>
                                    <td>
                                        <div class="text-danger">
                                            <i class="fa fa-times"></i>
                                        </div>
                                    </td>
                                    <td>
                                        {{ item.addressAddress + ' - ' + item.zoneName }}
                                    </td>
                                    <td>
                                        الاجمالي : {{ item.total }} د.ل
                                    </td>
                                    <td>
                                        {{ item.createdDate }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div><!-- End .product-desc-content -->
                    </div>
                    <pagination dir="rtl" v-model="page" :records="countOrders" :per-page="6" @paginate="setPage" ref="bottomPagination"/>
                </div>
                <div class="col-lg-4">
                    <img src="../../assets/images/account/orders.png" alt="">
                </div>
            </div>
        </div>

    </main>
</template>

<script>
export default {
    data() {
        return {
            order_id: 0,
            order_total: 0,
            order: [],
            delivered: [],
            pending: [],
            cancelled: [],
            countOrders: 0,
            checked: "pending",
            loading: false,
            page: 1,
        }
    },
    mounted() {
        this.getOrders('orderStatus.equals=DELIVERED')
        this.getOrders('orderStatus.notIn=CANCELLED&orderStatus.notIn=DELIVERED')
        this.getOrders('orderStatus.equals=CANCELLED')
    },
    methods: {
        getOrders: function(status) {
            this.axios({
                url: 
process.env.VUE_APP_URL+'/api/orders?page=0&size=6&'+status,
                method: 'GET',
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
            .then(response => {
                response.data.forEach(element => {
                    element.createdDate = /\d{4}-\d{2}-\d{2}/.exec(element.createdDate)['0']
                });
                // console.log(response.data)
                switch (status) {
                    case 'orderStatus.equals=DELIVERED':
                        this.delivered = response
                        break;
                    case 'orderStatus.notIn=CANCELLED&orderStatus.notIn=DELIVERED':
                        this.pending = response
                        this.countOrders = parseInt(response.headers["x-total-count"]);
                        console.log(parseInt(response.headers["x-total-count"]))
                        break;
                    case 'orderStatus.equals=CANCELLED':
                        this.cancelled = response
                        break;
                
                    default:
                        break;
                }
                this.loading = true;
            }) 
        },
        setPage: function(page) {
            this.loading = false;
            page = page-1;
            var status
            switch (this.checked) {
                case 'delivered':
                    status = 'orderStatus.equals=DELIVERED'
                    break;
                case 'pending':
                    status = 'orderStatus.notIn=CANCELLED&orderStatus.notIn=DELIVERED'
                    break;
                case 'cancelled':
                    status = 'orderStatus.equals=CANCELLED'
                    break;
                default:
                    break;
            }
            this.axios({
                url: 
process.env.VUE_APP_URL+'/api/orders?page='+page+'&size=6&'+status,
                method: 'GET',
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
            .then(response => {
                response.data.forEach(element => {
                    element.createdDate = /\d{4}-\d{2}-\d{2}/.exec(element.createdDate)['0']
                });
                switch (this.checked) {
                    case 'delivered':
                        this.delivered = response
                        break;
                    case 'pending':
                        this.pending = response
                        break;
                    case 'cancelled':
                        this.cancelled = response
                        break;
                    default:
                        break;
                }
                this.loading = true;
                
            })

        },
        changeTab: function() {
            this.loading = false;
            var newChecked = document.querySelector('input[name="orderStatus"]:checked').value
            switch (newChecked) {
                case 'delivered':
                    this.countOrders = parseInt(this.delivered.headers["x-total-count"]);
                    this.checked = 'delivered'
                    this.page = 1
                    break;
                case 'pending':
                    this.countOrders = parseInt(this.pending.headers["x-total-count"]);
                    this.checked = 'pending'
                    this.page = 1
                    break;
                case 'cancelled':
                    this.countOrders = parseInt(this.cancelled.headers["x-total-count"]);
                    this.checked = 'cancelled'
                    this.page = 1
                    break;
                default:
                    break;
            }
            this.loading = true;
        }
    }
}
</script>

<style scoped>
    .order {
        border-bottom-width: 1px;
        border-bottom-color: #0c0b0b;
        border-bottom-style: inset;
    }
    .order-product {
        background: white;
        border: 1px solid #ebebeb;
        border-radius: 0.5rem;
        padding: 5px 0px;
        height: 100%;
    }
    .no-orders {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .order-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .product-default .product-details {
        display: flex;
        flex-direction: row;
    }
    .list li a:before {
        content: none;
    }
    .list {
        text-align: right;
    }
    .dashboard-content {
        background-color: white;
        padding: 20px;
        border-radius: 46px;
    } 
    .order div {
        font-size: 20px;
        overflow: scroll;
        width: 100px;
    }

    /* tabs */

.tabs {
  display: flex;
  flex-wrap: wrap;
}

.tabs__label {
    padding: 7px 96px;
    cursor: pointer;
}

.tabs__radio {
  display: none;
}

.tabs__content {
  order: 1;
  width: 100%;
  border-bottom: 3px solid #dddddd;
  line-height: 1.5;
  font-size: 0.9em;
  display: none;
}

.tabs__radio:checked + .tabs__label {
  font-weight: bold;
  color: #e4007d;
  border-bottom: 2px solid #e4007d;
}

.tabs__radio:checked + .tabs__label + .tabs__content {
  display: initial;
}
label {
    color: #000000;
    font-size: 1.4rem;
    margin: 0px 0 1.6rem;
}
table {
    border-collapse: unset;
    border-spacing: 12px 6px;
}
.VuePagination {
    position: absolute;
    bottom: 0
}

</style>